import React from 'react';
import styles from './Card.module.scss'

function Card(props: any) {
  return (
    <div className={styles.base}>
      {props.children}
    </div>
  );
}

export default Card;
