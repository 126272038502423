import React from 'react';
import styles from "./InfoCard.module.scss"
import Card from "./Card";

const InfoCard = (props: any) => {
  return (
    <Card>
      <div className={styles.base}>
        <img src={props.imageLoc}/>
        <h2>{props.title}</h2>
        <p>{props.body}</p>
      </div>
    </Card>
  );
};

export default InfoCard;
