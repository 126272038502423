import React from 'react';
import PageBase from "../components/PageBase";
import styles from './Products.module.scss'
import Card from "../components/Card";

function Products() {
  return (
    <PageBase>
      <div style={{height: "6rem"}}/>
      <h1 style={{textAlign: "center"}}>Menu</h1>
      <p style={{textAlign: "center"}}>Please check for allergen information</p>
      <div className={styles.iconContainer}>
        <img className={styles.ingredientIcon} src="./images/FairTrade.jpg"/>
        <div className={styles.spacer}/>
        <img className={styles.ingredientIcon} src="./images/Organic.webp"/>
      </div>
      <div className={styles.productContainer}>
        <Card>
          <div className={styles.productCard}>
            <h1>Espresso</h1>
            <img src="./images/Espresso.jpg" />
            <h3>£2.50</h3>
          </div>
        </Card>
        <Card>
          <div className={styles.productCard}>
            <h1>Iced Coffee</h1>
            <img src="./images/Iced-Coffee.jpg" />
            <h3>£3.00</h3>
          </div>
        </Card>
        <Card>
          <div className={styles.productCard}>
            <h1>Tea</h1>
            <img src="./images/Tea.jpg" />
            <h3>£1.50</h3>
          </div>
        </Card>
        <Card>
          <div className={styles.productCard}>
            <h1>Frappuccino</h1>
            <img src="./images/Frappuccino.jpg" />
            <h3>£3.00</h3>
          </div>
        </Card>
        <Card>
          <div className={styles.productCard}>
            <h1>Breakfast</h1>
            <img src="./images/Breakfast.jpg" />
            <h3>£2.50</h3>
          </div>
        </Card>
        <Card>
          <div className={styles.productCard}>
            <h1>Toasties</h1>
            <img src="./images/Toasties.jpg" />
            <h3>£2.50</h3>
          </div>
        </Card>
      </div>
    </PageBase>
  );
}

export default Products;
