import React from 'react';
import PageBase from "../components/PageBase";
import styles from './Locations.module.scss'
import Card from "../components/Card";

function Locations() {
  return (
    <PageBase>
      <div className={styles.padding}/>
      <h1 className={styles.title}>Bean and Brew Locations</h1>
      <div className={styles.locationContainer}>
        <Card>
          <div className={styles.locationCard}>
            <h2 className={styles.locationTitle}>
              Hedge End
            </h2>
            <div className={styles.lineSpace}/>
            <h3 className={styles.locationLocation}>
              7 Upper Northam Road, Hedge End, Southampton, SO30 4DY
            </h3>
            <div className={styles.lineSpace}/>
            <h3>
              Opens 8:00<br/>
              Closes 20:00
            </h3>
            <div className={styles.lineSpace}/>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14229.796766904226!2d-1.308404705212893!3d50.91453289079459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487471fd74425f47%3A0x2f8a00d952754d23!2s7%20Upper%20Northam%20Rd%2C%20Hedge%20End%2C%20Southampton%20SO30%204DY!5e0!3m2!1sen!2suk!4v1661948196164!5m2!1sen!2suk"
              width="160" height="160" loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </Card>
        <Card>
          <div className={styles.locationCard}>
            <h2 className={styles.locationTitle}>
              Next Southampton
            </h2>
            <div className={styles.lineSpace}/>
            <h3 className={styles.locationLocation}>
              NEXT, Hedge End Retail Park, Charles Watts Way, Hedge End, Southampton, SO30 4RT
            </h3>
            <div className={styles.lineSpace}/>
            <h3>
              Opens 8:00<br/>
              Closes 21:00
            </h3>
            <div className={styles.lineSpace}/>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5030.591222465013!2d-1.3142744167665903!3d50.918248434440166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487471f984c3d7c1%3A0x5f40a50e4ddfe9d0!2sNext%20Home!5e0!3m2!1sen!2suk!4v1661948464115!5m2!1sen!2suk"
              width="160" height="160" loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </Card>
        <Card>
          <div className={styles.locationCard}>
            <h2 className={styles.locationTitle}>
              Antelope Park
            </h2>
            <div className={styles.lineSpace}/>
            <h3 className={styles.locationLocation}>
              Burlesdon Road, Southampton, Hampshire, SO19 8NE
            </h3>
            <div className={styles.lineSpace}/>
            <h3>
              Opens 8:00<br/>
              Closes 17:00
            </h3>
            <div className={styles.lineSpace}/>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.0753786255837!2d-1.3426620732099097!3d50.906340578084865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487471975a1fb343%3A0x27f3251e97074563!2sBursledon%20Rd%2C%20Southampton%20SO19%208NE!5e0!3m2!1sen!2suk!4v1661948612622!5m2!1sen!2suk"
              width="160" height="160" loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </Card>
        <Card>
          <div className={styles.locationCard}>
            <h2 className={styles.locationTitle}>
              Southampton Grenvill
            </h2>
            <div className={styles.lineSpace}/>
            <h3 className={styles.locationLocation}>
              Greenville House, Nelson Gate, Southampton, SO15 1GX
            </h3>
            <div className={styles.lineSpace}/>
            <h3>
              Opens 8:00<br/>
              Closes 19:00
            </h3>
            <div className={styles.lineSpace}/>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10063.10150524116!2d-1.417857729192221!3d50.909372268725036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487476bb12c69593%3A0xf3cbc598e8e655b4!2sStarbucks%20Coffee!5e0!3m2!1sen!2suk!4v1661948704990!5m2!1sen!2suk"
              width="160" height="160" loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </Card>
        <Card>
          <div className={styles.locationCard}>
            <h2 className={styles.locationTitle}>
              Hythe
            </h2>
            <div className={styles.lineSpace}/>
            <h3 className={styles.locationLocation}>
              22 High Street, Hythe, Southampton, SO45 6AH
            </h3>
            <div className={styles.lineSpace}/>
            <h3>
              Opens 8:00<br/>
              Closes 18:00
            </h3>
            <div className={styles.lineSpace}/>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10071.509930160739!2d-1.406337564809554!3d50.87046788046802!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4874772d0b137af1%3A0x915a4d098ed08479!2sCosta%20Coffee!5e0!3m2!1sen!2suk!4v1661948766635!5m2!1sen!2suk"
              width="160" height="160" loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </Card>
      </div>
    </PageBase>
  );
}

export default Locations;
