import React from 'react';
import PageBase from "../components/PageBase";
import styles from "./Home.module.scss"
import InfoCard from "../components/InfoCard";
import {Link} from "react-router-dom";

const Home = () => {
  return (
    <PageBase>
      <div className={styles.bannerImage} />
      <div className={styles.navGrid}>
        <Link className={styles.link} to="/locations">
          <InfoCard imageLoc="./images/Outside-Cafe.jpg" title="Locations" body="Bean and Brew have a huge variety of stores all over south east England just for you." />
        </Link>
        <Link className={styles.link} to="/ingredients">
          <InfoCard imageLoc="./images/Milk.jpg" title="Our Ingredients" body="We pride ourselves with the most organic and humanly sourced ingredients." />
        </Link>
        <Link className={styles.link} to="/products">
          <InfoCard imageLoc="./images/Muffin.jpg" title="Our Products" body="We sell a wide range of products at all of our stores." />
        </Link>
      </div>
      <div className={styles.aboutContainer}>
        <h1>About Us</h1>
        <div className={styles.aboutSpacer} />
        <p style={{fontSize: "1.3rem"}}>
          Bean and brew offers a completely unique environment to any other coffee shop out there and aim to give the #1 experience. We also make sure to supply a range of options for our product selection to the courses we supply.
        </p>
      </div>
    </PageBase>
  );
}

export default Home;
