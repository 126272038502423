import React, {useEffect, useRef, useState} from 'react';
import styles from './PageBase.module.scss'
import {Link} from "react-router-dom";
import Locations from "../pages/Locations";

type BoxProps = {
  children: React.ReactNode
}

const PageBase = (props: BoxProps) => {
  const [burgerState, setBurgerState] = useState(false);

  function toggleSideMenu() {
    setBurgerState(prevState => {
      return !prevState
    })
  }

  const buttonRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const menuRef = useRef() as React.MutableRefObject<HTMLInputElement>

  useEffect(() => {
    document.addEventListener('mousedown', (event: MouseEvent) => {
      if (!menuRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)) {
        setBurgerState(false)
      }
    })
  }, [])

  return (
    <div>
      <div className={styles.base}>
        <div className={styles.header}>
          <div>
            <div ref={buttonRef} className={styles.burgerMenuButton} onClick={toggleSideMenu}>
              <img src="./Burger-Menu_button.png"/>
            </div>
          </div>
          <div>
            <Link to={"/"}>
              <img className={styles.headerLogo} src="./BaB-Logo-C.webp"/>
            </Link>
          </div>
          {/*customer account here*/}
          <div>

          </div>
        </div>

        <div ref={menuRef} className={`${styles.burgerMenu} ${burgerState ? styles.burgerMenuOpen : ""}`}>
          <p>
            <Link to="/locations">Locations</Link><br/>
            <Link to="/ingredients">Ingredients</Link><br/>
            <Link to="/products">products</Link>
          </p>
        </div>
      </div>

      <div>{props.children}</div>

    </div>
  );
}

export default PageBase;
