import React from 'react';
import PageBase from "../components/PageBase";
import styles from './Ingredients.module.scss'

function Ingredients() {
  return (
    <PageBase>
      <div style={{height: "6rem"}}/>
      <div className={styles.container}>
        <p style={{fontSize: "1.3rem", maxWidth: "70rem", textAlign: "center"}}>
          Here at bean and brew we try our best to source the highest quality ingredients from the most ethical places
          to make sure that both you and our suppliers get the best deal possible
        </p>
        <div className={styles.iconContainer}>
          <img className={styles.ingredientIcon} src="./images/FairTrade.jpg"/>
          <div className={styles.spacer}/>
          <img className={styles.ingredientIcon} src="./images/Organic.webp"/>
        </div>
        <div style={{height: "4rem"}}/>
        <div className={styles.ingredientContainer}>
          <img src="./images/Milk.jpg"/>
          <div className={styles.spacer}/>
          <h1>Milk</h1>
          <div className={styles.spacer}/>
          <p>
            We love every cup of coffee – whether it’s espresso, latte, flat white or macchiato. So sourcing the right
            beans is very important to us. But is not just about the beans. We also need great quality milk chosen from
            completely organic sources.
          </p>
        </div>
        <div className={styles.ingredientContainer + " " + styles.revIfMobile}>
          <p>
            We want to make sure that all the coffee use to make the drinks that you love are ethically sourced from the
            people who need out business most. This is why we make sure that we only use fair trade Coffee.
          </p>
          <div className={styles.spacer}/>
          <h1>Coffee Beans</h1>
          <div className={styles.spacer}/>
          <img src="./images/Coffee-Bean.webp"/>
        </div>
        <div className={styles.ingredientContainer}>
          <img src="./images/Cocao.jpg"/>
          <div className={styles.spacer}/>
          <h1>Cocao</h1>
          <div className={styles.spacer}/>
          <p>
            We make sure to add the highest quality chocolate to all of our drinks to give them an unrivaled taste. We
            source our chocolate from
          </p>
        </div>
      </div>
    </PageBase>
  );
}

export default Ingredients;
